import React from "react"


export default function SectionChooseUs() {
return (
    <div className="bg-white py-24 sm:py-32">
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
            <div className="text-center">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Pourquoi choisir notre expertise fissures ?
                </h2>
                <p className="mt-4 text-lg leading-8 text-gray-600">
                Nous vous aidons à sauver votre maison. Accompagnement sur mesure. Vous n’êtes plus seul.
                </p>
            </div>
            <dl className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
                <div className="flex flex-col bg-gray-400/5 p-8">
                    <dt className="text-sm font-semibold leading-6 text-gray-600">experts spécialisés</dt>
                    <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">13</dd>
                </div>

                <div className="flex flex-col bg-gray-400/5 p-8">
                    <dt className="text-sm font-semibold leading-6 text-gray-600">indépendant des assurances</dt>
                    <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">100%</dd>
                </div>

                <div className="flex flex-col bg-gray-400/5 p-8">
                    <dt className="text-sm font-semibold leading-6 text-gray-600">pour obtenir un premier avis</dt>
                    <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">24h</dd>
                </div>

                <div className="flex flex-col bg-gray-400/5 p-8">
                    <dt className="text-sm font-semibold leading-6 text-gray-600">satisfaction client</dt>
                    <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">95%</dd>
                </div>
            </dl>
            <div className="text-center pt-14">
                <a
                href="/contact/"
                className="rounded-md bg-orange-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                >
                Nous contacter
                </a>
            </div>
        </div>
    </div>
    </div>
)
}
  