import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function Section2() {
    return (
      <div className="bg-sky-400 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Maison, appartement, immeuble. Interventions partout en France</h2>
            <p className="mt-2 text-lg leading-8 text-white">
            Découvrez nos solutions personnalisées pour garantir la sécurité et la durabilité de votre bien immobilier.
            </p>
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              <article
                className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-40 sm:pt-48 lg:pt-40"
              >
                <div className="absolute inset-0 -z-10 h-full w-full object-cover">
                  <StaticImage
                    className="absolute inset-0 -z-10 h-full w-full object-cover" 
                    src="../../images/expertise-fissures-independante.jpg"
                    alt="Expertise fissures indépendante"
                  />
                </div>
                <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                
                <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                  <a href="/services/expertise-fissures/">
                    <span className="absolute inset-0" />
                    Expertise fissures indépendante
                  </a>
                </h3>
              </article>

              <article
                className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-40 sm:pt-48 lg:pt-40"
              >
                <div className="absolute inset-0 -z-10 h-full w-full object-cover">
                  <StaticImage
                    className="absolute inset-0 -z-10 h-full w-full object-cover" 
                    src="../../images/expertise-secheresse.jpg"
                    alt="Expertise sécheresse"
                  />
                </div>
                <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                
                <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                  <a href="/services/expertise-secheresse/">
                    <span className="absolute inset-0" />
                    Expertise sécheresse
                  </a>
                </h3>
              </article>

              <article
                className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-40 sm:pt-48 lg:pt-40"
              >
                <div className="absolute inset-0 -z-10 h-full w-full object-cover">
                  <StaticImage
                    className="absolute inset-0 -z-10 h-full w-full object-cover" 
                    src="../../images/conseil-avant-achat-immobilier.jpg"
                    alt="Conseil avant achat immobilier"
                  />
                </div>
                <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                
                <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                  <a href="/services/conseil-achat-immobilier/">
                    <span className="absolute inset-0" />
                    Conseil avant achat immobilier
                  </a>
                </h3>
              </article>
          </div>
        </div>
      </div>
    )
  }
  