/* This example requires Tailwind CSS v2.0+ */
import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { ChevronRightIcon } from '@heroicons/react/solid'
import { ArrowCircleRightIcon } from '@heroicons/react/solid'

export default function Section1() {
  return (
    <div className="bg-white">
      <div className="relative isolate overflow-hidden bg-gradient-to-b from-sky-100/20">
        <div className="mx-auto max-w-7xl pb-24 pt-10 sm:pb-32 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-40">
          <div className="px-6 lg:px-0 lg:pt-4">
            <div className="mx-auto max-w-2xl">
              <div className="max-w-lg">
                {/* <img
                  className="h-11"
                  src="https://tailwindui.com/img/logos/mark.svg?color=sky&shade=600"
                  alt="Your Company"
                />
                <div className="mt-24 sm:mt-32 lg:mt-16">
                  <a href="#" className="inline-flex space-x-6">
                    <span className="rounded-full bg-sky-600/10 px-3 py-1 text-sm font-semibold leading-6 text-sky-600 ring-1 ring-inset ring-sky-600/10">
                      What's new
                    </span>
                    <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-600">
                      <span>Just shipped v0.1.0</span>
                      <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </a>
                </div> */}
                <h1 className="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                Expertise bâtiment et fissures
                </h1>

                <ul className="relative pl-9 my-8 list-outside list-disc text-gray-600 text-lg">
                  <li>
                  Pour le compte de propriétaires, syndics de copropriété et candidats à l’acquisition
                  </li>
                  <li>
                  Avis 100% indépendants des assureurs et entreprises de travaux 
                  </li>
                  <li>
                  Éclairage sur les causes des fissures, les risques et les réparations à envisager
                  </li>
                  <li>
                  Soutien technique dans le cadre vos recours (garantie décennale, catastrophe naturelle, vices cachés…)
                  </li>
                </ul>

                {/* <p className="mt-6 text-lg leading-8 text-gray-600">
                Nous fournissons des solutions d'expertise en fissuration de qualité supérieure pour protéger votre maison. Nos experts certifiés sont prêts à vous aider à résoudre tous vos problèmes de fissuration.
                </p> */}

                <div className="mt-10 flex items-center gap-x-6">
                  <a
                    href="/contact/"
                    className="rounded-md bg-orange-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                  >
                    Expliquez-nous votre besoin
                  </a>
                  {/* <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                    View on GitHub <span aria-hidden="true">→</span>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-20 sm:mt-24 md:mx-auto md:max-w-2xl lg:mx-0 lg:mt-0 lg:w-screen">
            <div
              className="absolute inset-y-0 right-1/2 -z-10 -mr-10 w-[200%] skew-x-[-30deg] bg-white shadow-xl shadow-sky-600/10 ring-1 ring-sky-50 md:-mr-20 lg:-mr-36"
              aria-hidden="true"
            />
            <div className="shadow-lg md:rounded-3xl">
              <div className="bg-sky-500 [clip-path:inset(0)] md:[clip-path:inset(0_round_theme(borderRadius.3xl))]">
                <div
                  className="absolute -inset-y-px left-1/2 -z-10 ml-10 w-[200%] skew-x-[-30deg] bg-sky-100 opacity-20 ring-1 ring-inset ring-white md:ml-20 lg:ml-36"
                  aria-hidden="true"
                />
                <div className="relative px-6 pt-8 sm:pt-16 md:pl-16 md:pr-0">
                  <div className="mx-auto max-w-2xl md:mx-0 md:max-w-none">
                    <div className="w-screen overflow-hidden rounded-tl-xl bg-gray-900">
                      <StaticImage 
                        src="../../images/expertise-fissuration.jpg" 
                        alt="Expertise Fissuration"
                      />
                      {/* <div className="flex bg-gray-800/40 ring-1 ring-white/5">
                        <div className="-mb-px flex text-sm font-medium leading-6 text-gray-400">
                          <div className="border-b border-r border-b-white/20 border-r-white/10 bg-white/5 px-4 py-2 text-white">
                            NotificationSetting.jsx
                          </div>
                          <div className="border-r border-gray-600/10 px-4 py-2">App.jsx</div>
                        </div>
                      </div>
                      <div className="px-6 pb-14 pt-6">

                      </div> */}
                    </div>
                  </div>
                  <div
                    className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 md:rounded-3xl"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
      </div>
    </div>







    // <div className="max-w-7xl mx-auto relative mt-8 sm:mt-16 lg:mt-16">
    //     <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
    //       <div className="mx-4 lg:col-start-2">
    //           <h1 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl md:text-6xl lg:text-5xl xl:text-5xl">
    //             <span className="block xl:inline">Débarrassez-vous durablement des </span>{' '}
    //             <span className="block text-sky-600 xl:inline"> fissures</span>
    //           </h1>
    //           <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
    //           Fissuration.fr, un service innovant et unique en France, pour vous aider à résoudre votre problématique de fissuration.
    //           </p>
    //           <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
    //           Causes des fissures, évaluation des risques, mesures conservatoires d’urgence, travaux de confortement des bâtiments, renforcement structurel et embellissement. 
    //           </p>
    //           <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
    //           Financement et garanties. Défense de vos intérêts, recours amiable et judiciaire.
    //           </p>
    //           <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
    //             <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
    //               <a
    //                 href="/contact/"
    //                 className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-sky-500 hover:bg-sky-700 md:py-4 md:text-lg md:px-10"
    //               >
    //                 Intéressé(e), cliquez-ici
    //               </a>
    //             </div>
    //           </div>
    //       </div>

    //       <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
    //           <div className="relative mx-auto">
    //             <StaticImage 
    //               src="../../images/fissuration.jpg" 
    //               alt="Fissuration"
    //               width={490}
    //               height={482}
    //             />
    //            </div>
    //       </div>
    //     </div>
    // </div>




  )
}
