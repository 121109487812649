import React from "react";

import { Helmet } from "react-helmet";
import Banner from "../components/headers/banner";
import Header from "../components/headers/header";
import Footer from "../components/footers/footer";
import SectionHero from "../components/index/SectionHero";
import SectionService from "../components/index/SectionService";
import SectionChooseUs from "../components/index/SectionChooseUs";
// import SectionInformationService from "../components/index/SectionInformationService"
import SectionAbout from "../components/index/SectionAbout";
import SectionBlog from "../components/index/SectionBlog";
import SectionCustomer from "../components/index/SectionCustomer";

export default function Index() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Expertise-Fissuration.fr</title>
        <meta
          name="description"
          content="Expertise-fissuration.fr, un service innovant et unique en France, pour vous aider à résoudre votre problématique de fissuration."
        />
        <link rel="canonical" href="https://www.expertise-fissuration.fr/" />
      </Helmet>
      <header>
        <Banner />
        <Header />
      </header>
      <main>
        <SectionHero />
        <SectionService />
        <SectionChooseUs />
        {/* <SectionInformationService /> */}
        <SectionAbout />
        <SectionBlog />
        <SectionCustomer />
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}
