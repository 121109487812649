import React from "react";
import { StaticImage } from "gatsby-plugin-image"


export default function SectionBlog() {
  return (
    <div className="relative bg-white py-12 sm:py-16 lg:py-20">
      <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
        <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        Actualités et fiches conseils sur les fissures
        </h2>
        <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
        Symptômes, causes, dangerosité, réparations immédiates, travaux confortatifs, recours amiable et judiciaire, démarche d’indemnisation auprès des assurances… 
        </p>


        <div className="relative bg-gray-50 pt-8 rounded-lg mt-8">
          <div className="relative overflow-hidden bg-sky-600 md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2 rounded-xl shadow-lg">
              <StaticImage
                  className="h-full w-full object-cover "
                  src="../../images/proprietaire-sinistre.jpg"
                  alt="Propriétaire sinistré"
              />
            <svg
              viewBox="0 0 926 676"
              aria-hidden="true"
              className="absolute -bottom-24 left-24 w-[57.875rem] transform-gpu blur-[118px]"
            >
              <path
                fill="url(#60c3c621-93e0-4a09-a0e6-4c228a0116d8)"
                fillOpacity=".4"
                d="m254.325 516.708-90.89 158.331L0 436.427l254.325 80.281 163.691-285.15c1.048 131.759 36.144 345.144 168.149 144.613C751.171 125.508 707.17-93.823 826.603 41.15c95.546 107.978 104.766 294.048 97.432 373.585L685.481 297.694l16.974 360.474-448.13-141.46Z"
              />
              <defs>
                <linearGradient
                  id="60c3c621-93e0-4a09-a0e6-4c228a0116d8"
                  x1="926.392"
                  x2="-109.635"
                  y1=".176"
                  y2="321.024"
                  gradientUnits="userSpaceOnUse"
                >
                  {/* <stop stopColor="#776FFF" />
                  <stop offset={1} stopColor="#FF4694" /> */}
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className="relative mx-auto max-w-7xl py-8 sm:py-32 lg:px-8 lg:py-8">
            <div className="pl-6 pr-6 md:ml-auto md:w-2/3 md:pl-16 lg:w-1/2 lg:pl-44 lg:pr-0 xl:pl-32">
              {/* <h2 className="text-base font-semibold leading-7 text-indigo-400">Award winning support</h2> */}
              <h3 className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">Propriétaires et sinistrés en souffrance</h3>
              <p className="mt-6 text-base leading-7 text-gray-600">
              Du fait de la sécheresse et d’autres causes, des milliers de maisons et immeubles se fissurent partout en France. Découvrez les cas de fissuration recensés à côté de chez vous. 
              </p>
              <div className="mt-8">
                <a
                    href="/actualites/"
                    className="rounded-md bg-white border px-3.5 py-2.5 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                >
                  Accès aux actualités
                </a>
              </div>
            </div>
          </div>
        </div>


        <div className="mt-24">
          <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">

            <a href="/conseils/inquietudes-fissures-maison/signes-fissures-preoccupantes/">
              <div className="pt-6">
                <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                  <div className="-mt-12">
                    <div>
                      <span className="inline-flex items-center justify-center rounded-lg shadow-lg bg-white">
                        <StaticImage
                            className="aspect-[7/5] rounded-t-2xl object-cover"
                            src="../../images/fissure-maison.jpg"
                            alt="Fissure maison"
                        />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                    Les signes indiquant que les fissures sur une maison sont préoccupantes
                    </h3>
                    {/* <p className="mt-5 text-base leading-7 text-gray-600">Description</p> */}
                  </div>
                </div>
              </div>
            </a>

            <a href="/conseils/declaration-fissures-secheresse/identifier-fissure-secheresse/">
              <div className="pt-6">
                <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                  <div className="-mt-12">
                    <div>
                      <span className="inline-flex items-center justify-center rounded-lg shadow-lg bg-white">
                      <StaticImage
                            className="aspect-[7/5] rounded-t-2xl object-cover"
                            src="../../images/fissure-secheresse.jpg"
                            alt="Fissure secheresse"
                        />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                    Comment identifier une fissure causée par la sécheresse ?
                    </h3>
                    {/* <p className="mt-5 text-base leading-7 text-gray-600">Description</p> */}
                  </div>
                </div>
              </div>
            </a>

            <a href="/conseils/types-fissures/fissures-maison-ancienne/">
              <div className="pt-6">
                <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                  <div className="-mt-12">
                    <div>
                      <span className="inline-flex items-center justify-center rounded-lg shadow-lg bg-white">
                      <StaticImage
                            className="aspect-[7/5] rounded-t-2xl object-cover"
                            src="../../images/fissure-maison-ancienne.jpg"
                            alt="Fissure maison ancienne"
                        />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                    Que faire en cas de fissure sur une maison ancienne ?
                    </h3>
                    {/* <p className="mt-5 text-base leading-7 text-gray-600">Description</p> */}
                  </div>
                </div>
              </div>
            </a>

            <a href="/conseils/expert-fissures-independant/contacter-expertise-independante/">
              <div className="pt-6">
                <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                  <div className="-mt-12">
                    <div>
                      <span className="inline-flex items-center justify-center rounded-lg shadow-lg bg-white">
                      <StaticImage
                            className="aspect-[7/5] rounded-t-2xl object-cover"
                            src="../../images/contact-expert-fissure.jpg"
                            alt="Contact expert fissure"
                        />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                    Qui contacter pour une expertise indépendante de fissures dans une maison ?
                    </h3>
                    {/* <p className="mt-5 text-base leading-7 text-gray-600">Description</p> */}
                  </div>
                </div>
              </div>
            </a>

            <a href="/conseils/expertise-fissure/processus-expertise-fissure/">
              <div className="pt-6">
                <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                  <div className="-mt-12">
                    <div>
                      <span className="inline-flex items-center justify-center rounded-lg shadow-lg bg-white">
                      <StaticImage
                            className="aspect-[7/5] rounded-t-2xl object-cover"
                            src="../../images/expertise-fissure-maison.jpg"
                            alt="Expertise fissure maison"
                        />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                    Comment se déroule l'expertise d'une fissure dans une maison ?
                    </h3>
                    {/* <p className="mt-5 text-base leading-7 text-gray-600">Description</p> */}
                  </div>
                </div>
              </div>
            </a>

            <a href="/conseils/resine-expansive/stabiliser-sol-resine/">
              <div className="pt-6">
                <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                  <div className="-mt-12">
                    <div>
                      <span className="inline-flex items-center justify-center rounded-lg shadow-lg bg-white">
                      <StaticImage
                            className="aspect-[7/5] rounded-t-2xl object-cover"
                            src="../../images/resine-expansive.jpg"
                            alt="Resine expansive"
                        />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                    Comment utiliser la résine expansive pour stabiliser un sol ?
                    </h3>
                    {/* <p className="mt-5 text-base leading-7 text-gray-600">Description</p> */}
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>

        <p className="mx-auto mt-14 max-w-prose text-xl text-gray-500">
          <a
              href="/conseils/"
              className="rounded-md bg-white border px-3.5 py-2.5 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
          >
          Voir tous les articles
          </a>
        </p>
      </div>
    </div>
  )
}


